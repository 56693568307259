import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';
import { useCreateTransaction } from '../hooks/useCreateTransaction';
import { useLogin } from '../hooks/useLogin';

const Users = () => {

	const { admin } = useAuthContext();
  	const { topUpCredit, deductCredit, setScoring, editPassword, setVolume, isLoading, error, actionSuccess, setActionSuccess } = useCreateTransaction();
	const { superLogin } = useLogin();

  	const [selectedUser, setSelectedUser] = useState(null);
  	const [txnType, setTxnType] = useState(0);
  	const [amount, setAmount] = useState(0);
	const [userScore, setUserScore] = useState(0);

  	const createTransaction = async (e) => {
		e.preventDefault();
		if (txnType == 1) {
			var balanceAfter = Number(selectedUser.cashWallet) + Number(amount);
			await topUpCredit(admin.token, selectedUser._id, selectedUser.username, amount, selectedUser.cashWallet, admin.username, balanceAfter);
			fetchUsers();
		} else if (txnType == 2) {
			var balanceAfter = Number(selectedUser.cashWallet) - Number(amount);
			await deductCredit(admin.token, selectedUser._id, selectedUser.username, amount, selectedUser.cashWallet, admin.username, balanceAfter);
			fetchUsers();
		}
	};

	const [newPassword, setNewPassword] = useState('');
	const changePassword = async (e) => {
		e.preventDefault();
		await editPassword(admin.token, selectedUser._id, newPassword);
	};

	const [userData, setUserData] = useState();
	const fetchUsers = async () => {
		const response = await fetch('https://api.csg9.com/user', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setUserData(json);
		}
	}

	const freezeUser = async (_userID, _kyc) => {
		const response = await fetch('https://api.csg9.com/user/'+_userID, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${admin.token}`
            },
			body: JSON.stringify({
                kyc: !_kyc,
            })
        })
        // const json = await response.json();
		fetchUsers();
	};

	useEffect(() => {
		fetchUsers();
	}, []);

	return (
		<>
			{/* Create Transaction */}
			<div className="modal fade" id="transactionModal" tabindex="-1" role="dialog" aria-labelledby="transaction" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Create Transaction</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser !== null ? (
									<form className="w-100" onSubmit={createTransaction}>
									<div className="mb-2">
										<label htmlFor="txnType">
										<strong>Transaction Type:</strong>
										</label>
										<select 
										name='txnType'
										className='form-control'
										onChange={(e) => setTxnType(e.target.value)}
										>
										<option value="0">Choose Transaction</option>
										<option value="1">Top Up Credit</option>
										<option value="2">Deduct Credit</option>
										</select>
									</div>
									<div className="mb-2">
										<label htmlFor="amount">
										<strong>Amount:</strong>
										</label>
										<input
										type="number"
										step="0.01"
										placeholder='Enter transaction amount'
										autoComplete='off'
										name='amount'
										className='form-control'
										onChange={(e) => setAmount(e.target.value)}
										/>
									</div>
									{txnType == 0 ? (
										<></>
									):(
										<>
										<div className="mt-3">
										<label><strong>Confirm Transaction:</strong></label>
										{txnType == 1 ? <p className="">Top Up <strong><span className="profit-green">{parseFloat(amount).toFixed(2)}</span></strong> for <strong>{selectedUser.username}</strong></p> : null}
										{txnType == 2 ? <p className="">Deduct <strong><span className="error">{parseFloat(amount).toFixed(2)}</span></strong> for <strong>{selectedUser.username}</strong></p> : null}
										</div>
										<button disabled={isLoading} type="submit" className="btn action-button w-100">
										<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
										</>
									)}

									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Transaction Success</h5>
								</div>
							</div>
						)}

					</div>
				</div>
			</div>

			{/* Change Password */}
			<div className="modal fade" id="changePasswordModal" tabindex="-1" role="dialog" aria-labelledby="changePassword" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLongTitle">Change Password</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true"><i className="fa-solid fa-circle-xmark"></i></span>
							</button>
						</div>
						{!actionSuccess ? (
							<div className="modal-body">
								{selectedUser != null ? (
									<form className="w-100" onSubmit={changePassword}>
										<div className="mb-2">
											<label htmlFor="newPW">
												<strong>New Password</strong>
											</label>
											<input
												type="password"
												placeholder='Enter new password'
												autoComplete='off'
												name='newPW'
												className='form-control'
												onChange={(e) => setNewPassword(e.target.value)}
											/>
										</div>
										<button disabled={isLoading} type="submit" className="btn action-button w-100">
											<p className="text-white mb-0">Confirm</p>
										</button>
										{error && <div className="error">{error}</div>}
									</form>
								):(
									<p className="error mb-0">Failed to fetch user data. Please refresh page.</p>
								)}
							</div>
						):(
							<div className="modal-body">
								<div className="text-center">
									<span className="success-icon"><i className="fa-solid fa-circle-check"></i></span>
									<h5 className="">Password Change Success</h5>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Manage Users</h3>
						<p className="mt-2 mb-0">Total {userData && userData.length} Users</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">KYC</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Username</p></th>
									<th className="p-3" scope="col"><p className="mb-0">IC/Passport</p></th>
									<th className="p-3" scope="col"><p className="mb-0">个人入股</p></th>
									<th className="p-3" scope="col"><p className="mb-0">等级</p></th>
									<th className="p-3" scope="col"><p className="mb-0">业绩</p></th>
									{/* <th className="p-3" scope="col"><p className="mb-0">Country</p></th> */}
									<th className="p-3" scope="col"><p className="mb-0">可提盈利</p></th>
									<th className="p-3" scope="col"><p className="mb-0">对冲池</p></th>
									<th className="p-3" scope="col"><p className="mb-0">保险池</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Full Name</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Phone</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Email</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Registered</p></th>
								</tr>
							</thead>
							<tbody>
								{userData && userData.map((user, index) => (
									<tr key={index}>
										{/* <td className="px-3 py-3">
											<p className="mb-0">{user.username}</p>
											<button className="btn action-button unselected-btn mt-2" data-toggle="modal" data-target="#changePasswordModal" onClick={(e) => {
												setActionSuccess(false);
												setSelectedUser(user);
											}}>
												<p className="mb-0"><i className="fa-solid fa-key mr-2"></i>Reset PW</p>
											</button>
										</td> */}

										<td className="px-3 py-3">
											<p className={!user.kyc ? "error mb-0" : "profit-green mb-0" }><strong>{!user.kyc ? "Incomplete" : "Verified" }</strong></p>
											<label class="switch">
												<input type="checkbox"
													value={!user.kyc ? "" : "checked"}
													checked={user.kyc}
													onChange={() => freezeUser(user._id, user.kyc)}
												></input>
												<span class="slider round"></span>
											</label>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{user.username}</p>
											{/* <p className="mb-0">{user._id}</p> */}
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{user.national_id}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{parseFloat(user.total_deposit).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{user.rank}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>直推: </strong>{parseFloat(user.direct_sales).toFixed(2)}</p>
											<p className="mb-0"><strong>团队: </strong>{parseFloat(user.total_team_sales-user.direct_sales).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{parseFloat(user.withdrawable).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{parseFloat(user.split_bal).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{parseFloat(user.insurance_bal).toFixed(2)}</p>
										</td>
										{/* <td className="px-3 py-3">
											<p className="mb-0">{user.country != "" ? user.country : "MY"}</p>
										</td> */}
										<td className="px-3 py-3">
											<p className="mb-0">{user.fullname != "" ? user.fullname : "-"}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{user.phone != "" ? user.phone : "-"}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{user.email != "" ? user.email : "-"}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(user.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  	);

};

export default Users;