import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';

const Deposits = () => {

	const { admin } = useAuthContext();

	const [depositData, setDepositData] = useState();
	const fetchAllDeposits = async () => {
		const response = await fetch('https://api.csg9.com/deposit', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setDepositData(json);
		}
	}

	useEffect(() => {
		fetchAllDeposits();
	}, []);

	return (
		<>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Deposit Records</h3>
						<p className="mt-2 mb-0">Total Deposit: -</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">时间</p></th>
									<th className="p-3" scope="col"><p className="mb-0">Username</p></th>
									<th className="p-3" scope="col"><p className="mb-0">数额</p></th>
								</tr>
							</thead>
							<tbody>
								{depositData && depositData.map((deposit, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(deposit.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
											<p className="mb-0" style={{fontSize:"14px"}}>#{deposit._id}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{deposit.username}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{parseFloat(deposit.deposit_amount).toFixed(2)}</p>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  	);

};

export default Deposits;