import { useState } from 'react';

export const useCreateTransaction = () => {
    const [error, setError] = useState(null);
    const [actionSuccess, setActionSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(null);

    const topUpCredit = async (authToken, curUserId, username, txnAmount, curCashBal, adminUsername, walletBal) => {
        setIsLoading(true);
        setActionSuccess(false);
        setError(null);
        const response = await fetch('https://api.csg9.com/txn/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                txnId: "TU_",
                txnType: "Top Up Credit",
                username,
                amount: txnAmount, 
                walletBefore: curCashBal,
                walletAfter: walletBal,
                createdBy: adminUsername,
                remarks: ""
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            const response = await fetch('https://api.csg9.com/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({cashWallet: walletBal})
            })
            const updateBal = await response.json();
            if (!response.ok) {
                setIsLoading(false);
                setError(updateBal.error);
            }
            if (response.ok) {
                setIsLoading(false);
                setActionSuccess(true);
            }

		}
    }

    const editPassword = async (authToken, curUserId, newPW) => {
        setActionSuccess(false);
        setIsLoading(true);
        setError(null);

        if (newPW === '') {
            setError("Password cannot be empty");
            setIsLoading(false);
            return;
        } else {
            const response = await fetch('https://api.csg9.com/user/update/password', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    userID: curUserId,
                    newPassword: newPW
                })
            })
            const json = await response.json();
    
            if (!response.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (response.ok) {
                setIsLoading(false);
                setActionSuccess(true);
            }
        }
    }

    const bonusComm = async (authToken, curUserId, username, txnAmount, curCashBal, adminUsername, walletBal) => {
        setIsLoading(true);
        setActionSuccess(false);
        setError(null);
        const response = await fetch('https://api.csg9.com/txn/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                txnId: "OC_",
                txnType: "Order Commission",
                username,
                amount: txnAmount, 
                walletBefore: curCashBal,
                walletAfter: walletBal,
                createdBy: adminUsername,
                remarks: ""
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
            const response = await fetch('https://api.csg9.com/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({cashWallet: walletBal})
            })
            const updateBal = await response.json();
            if (!response.ok) {
                setIsLoading(false);
                setError(updateBal.error);
            }
            if (response.ok) {
                setIsLoading(false);
                setActionSuccess(true);
            }

		}
    }

    const deductCredit = async (authToken, curUserId, username, txnAmount, curCashBal, adminUsername, walletBal) => {
        setIsLoading(true);
        setActionSuccess(false);
        setError(null);
        const response = await fetch('https://api.csg9.com/txn/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                txnId: "DC_",
                txnType: "Deduct Credit",
                username,
                amount: txnAmount, 
                walletBefore: curCashBal,
                walletAfter: walletBal,
                createdBy: adminUsername,
                remarks: ""
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {

            const response = await fetch('https://api.csg9.com/user/' + curUserId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({cashWallet: walletBal})
            })
            const updateBal = await response.json();
            if (!response.ok) {
                setIsLoading(false);
                setError(updateBal.error);
            }
            if (response.ok) {
                setIsLoading(false);
                setActionSuccess(true);
            }
		}
    }

    const setScoring = async (authToken, curUserId, curUserPoints, newScore) => {
        setIsLoading(true);
        setError(null);
        var newScore = Number(curUserPoints)+Number(newScore);
        const response = await fetch('https://api.csg9.com/user/' + curUserId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                points: newScore,
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
            setActionSuccess(true);
        }
    }

    const setVolume = async (authToken, curUserId, curUserVolume, newVolume) => {
        setIsLoading(true);
        setError(null);
        const response = await fetch('https://api.csg9.com/user/' + curUserId, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
			body: JSON.stringify({
                matchVolume: Number(newVolume),
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            setIsLoading(false);
            setActionSuccess(true);
        }
    }

    return { topUpCredit, bonusComm, deductCredit, setScoring, editPassword, setVolume, isLoading, error, actionSuccess, setActionSuccess };
}