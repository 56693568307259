import { useState } from 'react';

export const useWithdrawal = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);

    const updateWithdrawalStatus = async (authToken, adminUsername, withdrawalUser, withdrawalID, withdrawAmount, newStatus, newRemarks) => {
        setIsLoading(true);
        setError(null);

        const response = await fetch('https://api.csg9.com/withdrawal/' + withdrawalID, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify({
                status: newStatus,
                processBy: adminUsername,
                remarks: newRemarks
            })
        })
        const json = await response.json();

        if (!response.ok) {
            setIsLoading(false);
            setError(json.error);
        }
        if (response.ok) {
            if (response.status == "Rejected") {
                setIsLoading(false);
                setError("Transaction is already rejected");
                return;
            }
            if (newStatus == "Rejected") {
                const getUserID = await fetch('https://api.csg9.com/user/' + withdrawalUser, {
                    headers: {
                        'Authorization': `Bearer ${authToken}`
                    }
                });
                const userID = await getUserID.json();
                if (getUserID.ok) {
                    const updateBal = await fetch('https://api.csg9.com/user/' + userID[0]._id, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`
                        },
                        body: JSON.stringify({cashWallet: Number(userID[0].cashWallet)+Number(withdrawAmount)})
                    })
                }
            }

            const audit = await fetch('https://api.csg9.com/audit/log', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    adminName: adminUsername,
                    action: "Update Withdrawal Status",
                    refID: withdrawalID
                })
            })
            const json = await audit.json();
            if (!audit.ok) {
                setIsLoading(false);
                setError(json.error);
            }
            if (audit.ok) {
                setIsLoading(false);
            }
        }
    }

    return { updateWithdrawalStatus, isLoading, error };
}