import React, { useEffect, useState } from 'react';
import Moment from 'moment';

// hooks
import { useAuthContext } from '../hooks/useAuthContext';

const Withdrawals = () => {

	const { admin } = useAuthContext();

	const [withdrawalData, setWithdrawalData] = useState();
	const fetchAllWithdrawals = async () => {
		const response = await fetch('https://api.csg9.com/withdrawal', {
			headers: {
				'Authorization': `Bearer ${admin.token}`
			}
		});
		const json = await response.json();
		if (response.ok) {
			setWithdrawalData(json);
		}
	}

	useEffect(() => {
		fetchAllWithdrawals();
	}, []);

	return (
		<>

			<div className="container-fluid fluid-padding">
				<div className="row my-3">
					<div className="col-12">
						<h3 className="mt-3 mb-0">Withdrawal Records</h3>
						<p className="mt-2 mb-0">Total Withdrawal: -</p>
					</div>
					<div className="col-12 mt-3">
						<table className="table table-responsive table-hover table-striped">
							<thead className="thead-dark">
								<tr className="">
									<th className="p-3" scope="col"><p className="mb-0">时间</p></th>
									<th className="p-3" scope="col"><p className="mb-0">用户ID</p></th>
									<th className="p-3" scope="col"><p className="mb-0">钱包地址</p></th>
									<th className="p-3" scope="col"><p className="mb-0">提现数额</p></th>
									<th className="p-3" scope="col"><p className="mb-0">收据</p></th>
								</tr>
							</thead>
							<tbody>
								{withdrawalData && withdrawalData.map((withdrawal, index) => (
									<tr key={index}>
										<td className="px-3 py-3">
											<p className="mb-0">{Moment(withdrawal.createdAt).format('YYYY/MM/DD HH:mm')}H</p>
											<p className="mb-0" style={{fontSize:"14px"}}>#{withdrawal._id}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{withdrawal.username}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0">{withdrawal.wallet}</p>
										</td>
										<td className="px-3 py-3">
											<p className="mb-0"><strong>提现：</strong>{parseFloat(withdrawal.withdrawal_amount).toFixed(2)}</p>
											<p className="mb-0"><strong>所得：</strong>{parseFloat(withdrawal.usdt_amount).toFixed(2)}</p>
										</td>
										<td className="px-3 py-3">
											{withdrawal.txn_link == "" ? (
												<p className="mb-0">{withdrawal.status}</p>
											):(
												<p className="mb-0"><a href={withdrawal.txn_link} target="_blank"><i className="fa-regular fa-link"></i></a></p>
											)}

										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>

		</>
  	);

};

export default Withdrawals;